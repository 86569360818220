import BgVideoPage from "./BgVideoPage";
import Overview from "./Overview/index";
import SamaGoals from "./SamaGoals";



function About() {
  return (
    <>
      <BgVideoPage></BgVideoPage>
      <Overview></Overview>
      <SamaGoals></SamaGoals>
      
    </>
  )
}
export default About;